import React, { Component } from "react"
import { navigate } from 'gatsby'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Paseo from '../components/Validar/Paseo';
import 'bootstrap/dist/css/bootstrap.css'
import './Validar/Validar.css'
import lomito1 from '../assets/Assets_Lomito1Crop.png'
import lomito2 from '../assets/Assets_Lomito2Crop.png'
import lomito3 from '../assets/Assets_Lomito3Crop.png'
import boneSound from '../assets/Audio/bone.wav';
import boneSound2 from '../assets/Audio/bone2.wav';
import pathSound from '../assets/Audio/path.mp3';
import tourSound from '../assets/Audio/tour.mp3';

let boneAudio;
let boneAudio2;
let pathAudio;
let tourAudio;

if (typeof window !== 'undefined') {
  boneAudio = new Audio(boneSound);
  boneAudio2 = new Audio(boneSound2);
  pathAudio = new Audio(pathSound);
  tourAudio = new Audio(tourSound);
}
const randomLomito = [lomito1,lomito2,lomito3][Math.floor(Math.random()*3)]

const SERVER_HOST = "https://lomito-city-backend.herokuapp.com";
// = "http://localhost:3000"
const mySwal = withReactContent(Swal);
const huesoPath = `M35.45,6.656c-0.839-0.839-1.973-1.285-3.123-1.285c-0.37,0-0.74,0.049-1.104,0.142c0.387-1.491-0.032-3.116-1.144-4.227
C29.222,0.428,28.093,0,26.966,0s-2.253,0.428-3.114,1.286c-1.568,1.571-1.686,4.034-0.388,5.757L7.078,23.503l0,0l-0.001,0.001
c-0.785-0.597-1.724-0.892-2.662-0.892c-1.121,0-2.239,0.422-3.091,1.278c-1.718,1.718-1.718,4.51,0,6.228
c0.839,0.84,1.973,1.285,3.122,1.285c0.37,0,0.74-0.049,1.104-0.142c-0.386,1.49,0.032,3.116,1.143,4.227
c0.858,0.858,1.986,1.286,3.114,1.286c1.126,0,2.254-0.428,3.115-1.286c1.568-1.571,1.686-4.033,0.388-5.758l16.387-16.459
l0.001-0.001c0.784,0.597,1.724,0.892,2.663,0.892c1.12,0,2.238-0.422,3.091-1.278C37.168,11.166,37.168,8.374,35.45,6.656z`

/*TODO:
Algo interesante sería tomar cada uno de los steps y armar
una especie de "shopping list" en la que se van palomeando
uno tras otro conforme te acerques a ellos.
*/

let follow_validation;
let follow_step;
let path_index = 1; //Count up until the tour is done
let codigo_direccion = 1;
let img_sel = Math.floor(Math.random() * 3)+1;
const step = [];
const pbHeight = '12px'
const teamID = "3973891077675672561";
let points = 0;
const awardConstant = 200; //Points awarded for finding a bone

class Validar extends Component {
  //TODO: Todavía tenemos que ver qué hacer con la primera instrucción
  //El primer huesito no es muy intuitivo siempre.
  /* 
  This block handles the initial setup for the Google Street View Panorama.
  It feeds into Paseo, which in turn feeds into StreetView, both of which are
  components which are defined in the components folder.
  Changing this will change the props for Paseo, which will in turn change its
  state. Therefore, it will override any "immersion-driven" changes such as moving
  the panorama around in Street View.
  Therefore, it should not be altered except for special occasions:
    >When changing the location to a new random area.
    >When "redirecting" a lost user to the second or third leg of a tour.
  */
  state = {
    team_id: 3973891077675672561,
    tour_id: "",
    path_index: 1,
    start: {
      lat: 19.4087257,
      lng: -99.1765624,
      heading: 15.87,
      description: "",
      indoor: false
    },
    end: {
      lat: 19.409898,
      lng: -99.175258,
      heading: 5,
      description: "",
      indoor: false
    },
    step: [
      {
        "lat": 19.408858,
        "lng": -99.176585,
        "heading": 15.87,
        "description": "A tu derecha hay un terreno baldío, camina hacia allá",
        "indoor": false
      }
    ]
  }

  formatURL = (url) => {
    return url.replace(/\+/g, '%2B');
  }

  formatCoords = (coord) => {
    return (Math.round(coord * 10000000));
  }

  getDataFromArgsJsonResult = (argsJsonResult) => {
    if (argsJsonResult) {
      let uriData = argsJsonResult.uri.split('base64,');
      if (uriData.length >= 2) {
        let data = atob(uriData[1]);
        return JSON.parse(data);
      }
    }
    return JSON.parse("{}");
  }

  modifyAssignment = (workerName, typesToAdd, typesToRemove) => {
    const modifyPromise = new Promise(async (resolve, reject) => {
      const response = await (window.gapi.client.datacompute.worker_pools.workers //TODO: Aquí dice que esto es undefined y truena
        .modifyAssignments(
          { 'name': workerName }, { 'addedQuestionTypes': typesToAdd, 'removedQuestionTypes': typesToRemove }))
      resolve(response)
    })

    return modifyPromise
  }

  assignPathValidationQuestion = () => {
    const worker = JSON.parse(window.localStorage.getItem('datacomputeWorker'))
    const publicName = worker.result.name;
    const assignments = worker.result.assignments;
    const typeToAssign = 'workspaces/mx_spatial_navigation/question_types/spatial_language_path_validation'
    const typesToRemove = (!assignments || !assignments.questionTypes) ? [] : assignments.questionTypes.filter(el => el != typeToAssign);

    const assignPromise = new Promise((resolve, reject) => {
      this.modifyAssignment(publicName, [typeToAssign], typesToRemove)
        .then(resp => { //Asignar pregunta
          // console.log('nodeberiaentrarrrrx2', resp)

          window.gapi.client.datacompute.workspaces.leaseQuestions({ 'workerName': publicName })
            .then(res => {
              // console.log('nodeberiaentrarr', res)
              const { leases } = res.result;
              const question = leases[leases.length - 1];
              //Almacena info de question en localstorage
              window.localStorage.setItem('question', JSON.stringify(question));
              const currentQuestionName = question.questionName;

              //Obtener el payload, ya que estamos en validación
              window.gapi.client
                .request({
                  'path': `https://content-datacompute.googleapis.com/v1/${
                    this.formatURL(currentQuestionName)}:listQuestionPayloads?pageSize=10`,
                  'method': 'GET',
                })
                .then(validate => {
                  // console.log(validate);
                  if (validate.result.payloads) {
                    let data = this.getDataFromArgsJsonResult(validate.result.payloads.find((payload) => {
                      return payload.name && payload.name == 'args.json';
                    }));
                    // console.log("Payload recibido: ")
                   
                    resolve(data);
                  }
                })
              //return(leases[leases.length-1]);
            })
            .catch(err => {
              console.error('Error:', Error)
              mySwal.fire({
                title: "Ups",
                text: "No pudimos asignarte este paseo, por favor escríbenos a lomito.city@gmail.com.",
                type: "error",
                imageUrl: randomLomito,
                imageHeight: 200
              })
                .then(() => {
                  navigate('/app/mode-select');
                  return; //TODO: Corregir esta redirección
                })
            })

          //Actualiza assignments de worker en localstorage
          window.gapi.client.datacompute.worker_pools.workers
            .get({ 'name': publicName, 'includeAssignments': true })
            .then((resp) => {
              window.localStorage.setItem("datacomputeWorker", JSON.stringify(resp));
            });
        })
    })
    return assignPromise
  }

  reconvertCoords = (coord) => { 
    return (coord / 10000000);
  }

  cleanPath = (path) => {
    path.team_id = path.team_id;
    path.tour_id = path.tour_id;
    path.start.heading = parseFloat(path.start.heading)
    path.start.lat = this.reconvertCoords(path.start.lat);
    path.start.lng = this.reconvertCoords(path.start.lng);
    path.start.indoor = path.start.indoor;
    path.end.heading = parseFloat(path.end.heading)
    path.end.lat = this.reconvertCoords(path.end.lat);
    path.end.lng = this.reconvertCoords(path.end.lng);
    path.end.indoor = path.end.indoor;
    path.step = path.step.map(el => {
      el.description = el.description;
      el.heading = parseFloat(el.heading)
      el.lat = this.reconvertCoords(el.lat);
      el.lng = this.reconvertCoords(el.lng);
      el.indoor = el.indoor;
      return el;
    })

    return path;

  }

  componentDidMount = async () => {

    const rawTour = await this.assignPathValidationQuestion(); //TODO: ¿Esto va a funcionar? Es lo último
    // console.log(rawTour)
    const tour = this.cleanPath(rawTour);
    // console.log(tour)
    this.setState(tour)
    mySwal.fire({
      title: "Inicio",
      text: this.state.start.description,
      imageUrl: randomLomito,
      imageHeight: 200
    }).then(() => {
      mySwal.fire({
        title: "Pista inicial",
        text: this.state.step[0].description,
        imageUrl: randomLomito,
        imageHeight: 200
      })
    })
    document.getElementById('direccion-actual').textContent = tour.start.description;
    // const huesos = document.getElementById('huesitos');
    // const huesoContents = <div id="hueso-contents" className="huesitos">
    // {tour.step.map((el, i) => {
    //   if (i === tour.step.length-1) {
    //     return (
    //       <img key={i} onClick={this.mostrarDirecciones} className="huesito-item" src="/huesote-inactive.svg" alt="" srcset=""/>
    //     )
    //   }
    //   else {
    //     return (
    //       <img key={i} onClick={this.mostrarDirecciones} className="huesito-item" src="/hueso-inactive.svg" alt="" srcset=""/>
    //     )
    //   }
    //   }
    // )}
    // </div>

    // huesos.appendChild(huesoContents);

    follow_step = this.state.step.map(el => {
      return ({
        step_location: { //TODO: ¿Esto sirve?
          description: el.description,
          heading: (el.heading).toString(),
          lat: this.formatCoords(el.lat),
          lng: this.formatCoords(el.lng),
          indoor: el.indoor
        },
        status: null
      });
    });
    follow_validation = {
      follow_step: follow_step,
      description: "",
      follow_team_id: teamID
    }
  }

  // //TODO: Eliminar de production
  // revisarFollowValidation = () => {
  //   console.log(follow_validation);
  // }
  instructionFailed = () => {
    let lomitoUser = JSON.parse(window.localStorage.getItem('lomitoUser'));
    mySwal.fire({
      title: "Gracias por avisarnos",
      text: "Con tu apoyo podremos trabajar en conseguir mejores direcciones para todos.",
      imageUrl: randomLomito,
      imageHeight: 200
    }).then(() => {
      codigo_direccion = 2;
      mySwal.fire({
        title: '¿Nos ayudas?',
        text: '¿Nos puedes ayudar a validar los textos de las demás instrucciones? ¿Crees que son claras o nadie las podría navegar?',
        type: 'question',
        imageUrl: randomLomito,
        imageHeight: 200
      }).then((result) => {
        if (result.value) {
          const inputOptions = {
            '1': "Claro",
            '2': "No entendí",
            '3': "Lenguaje inapropiado"
          }

          Swal.mixin({
            input: 'radio',
            inputOptions: inputOptions,
            confirmButtonText: 'Siguiente &rarr;',
            showCancelButton: true,
            cancelButtonText: "No quiero ayudar",
            progressSteps: this.state.step.map((el, i) => i),
          }).queue(
            this.state.step.map((el, i) => {
              return ({
                title: `Instrucción ${i}`,
                text: el.description
              })
            })
          ).then((result) => {
            let answers = result.value;
            // console.log(answers);
            //If at least two other instructions were not accepted, refresh
            if (answers.filter(el => el === "2").length > 1) {
              mySwal.fire({
                title: "Muchas gracias",
                text: "Agradecemos tu apoyo y paciencia.",
                imageUrl: randomLomito,
                imageHeight: 200
              }).then(() => {

                const data = {
                  team_id: this.state.team_id,
                  tour_id: this.state.tour_id,
                  path_index: this.state.path_index,
                  start: {
                    description: this.state.start.description,
                    heading: this.state.start.heading.toString(),
                    lat: this.formatCoords(this.state.start.lat),
                    lng: this.formatCoords(this.state.start.lng),
                    indoor: this.state.start.indoor
                  },
                  end: {
                    description: this.state.end.description,
                    heading: this.state.end.heading.toString(),
                    lat: this.formatCoords(this.state.end.lat),
                    lng: this.formatCoords(this.state.end.lng),
                    indoor: this.state.end.indoor
                  },
                  step: this.state.step.map(el => {
                    return ({
                      description: el.description,
                      heading: el.heading.toString(),
                      lat: this.formatCoords(el.lat),
                      lng: this.formatCoords(el.lng),
                      indoor: el.indoor
                    });
                  }),
                  follow_validation: {
                    follow_step: follow_validation.follow_step.map((el, i) => {
                      return ({
                        step_location: {
                          description: el.step_location.description,
                          heading: el.step_location.heading.toString(),
                          lat: el.step_location.lat,
                          lng: el.step_location.lng,
                          indoor: el.step_location.indoor
                        },
                        status: answers[i]
                      })
                    }),
                    description: "",
                    follow_team_id: teamID
                  }
                };

                let options = {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json" //Aguas con esto, es super importante
                  },
                  body: JSON.stringify({data, user: lomitoUser, points: points})
                };
                // console.log(data);
                //Enviar primero a Backend y después a Google
                fetch(`${SERVER_HOST}/api/validate-path`, options)
                  .then(res => {
                    const currentQuestionName = JSON.parse(window.localStorage.getItem('question')).questionName;
                    const worker = JSON.parse(window.localStorage.getItem('datacomputeWorker'))

                    // console.log("Request: ")
                    // console.log({
                    //   'path': `https://datacompute.googleapis.com/v1/${
                    //     this.formatURL(currentQuestionName)}:submitAnswer?`,
                    //   'method': 'POST',
                    //   'body': {
                    //     'userName': worker.result.name,
                    //     'clientAnswerData': {
                    //       'answerData': btoa(JSON.stringify(data))
                    //     }
                    //   }
                    // });

                    window.gapi.client //Google
                      .request({
                        'path': `https://datacompute.googleapis.com/v1/${
                          this.formatURL(currentQuestionName)}:submitAnswer?`,
                        'method': 'POST',
                        'body': {
                          'userName': worker.result.name,
                          'clientAnswerData': {
                            'answerData': btoa(JSON.stringify(data))
                          }
                        }
                      })
                      .then(resp => {
                        // console.log(resp);
                        if (resp.result['name']) {  // Means response was successful.
                          this.removeQuestionFromLeases(currentQuestionName);
                          res.json().then(obj => {
                            mySwal.fire({
                              title: "¡Lo lograste!",
                              text: "Ahora tu lomito es más feliz.",
                              imageUrl: randomLomito,
                              imageHeight: 200
                            })
                              .then(() => {
                                pathAudio.play();
                                points = 0;
                                navigate("/app/paseo-success");
                              })
                          })
                            .catch(err => console.log(err));
                        }
                      }).catch(err => console.log(err));
                  })
                  .catch(err => console.log(err));

              })
            }
          })
        }
      })
    }
    );
  }

  badLanguage = () => {
    mySwal.fire({
      title: "Lo sentimos",
      text: "Te pedimos una disculpa por el lenguaje. No queremos ofender a nadie en Lomitocity. Gracias por tu reporte.",
      imageUrl: randomLomito,
      imageHeight: 200
    })
    codigo_direccion = 3;
  }

  handleLocation = (params) => {
    document.getElementById('lat-input').value = params.lat;
    document.getElementById('lng-input').value = params.lng;
    document.getElementById('heading-input').value = params.heading;
  }

  boneFound = () => {
    if (Math.floor(Math.random() * 2) === 1) {
      boneAudio.play();
    }
    else {
      boneAudio2.play();
    }
    points = points + awardConstant;
    // console.log(points);
  }

  removeQuestionFromLeases = () => {
    window.localStorage.setItem('question', JSON.stringify({}));
  }

  pickDirection = (n) => {
    mySwal.fire({
      title: `Encontraste la pista número ${n}`,
      text: `${this.state.step[n].description}
      \n
      Llevas ${points} puntos en esta sesión`,
      imageUrl: randomLomito,
      imageHeight: 200
    })

    /* 
    Cuando agarras un huesito, estás validando la dirección ANTERIOR
    */
    //Cambia status
    if (codigo_direccion === 1) {
      follow_validation.follow_step[n - 1].status = "1";
    } else if (codigo_direccion === 2) {
      follow_validation.follow_step[n - 1].status = "2";
      codigo_direccion = 1;
    } else if (codigo_direccion === 3) {
      follow_validation.follow_step[n - 1].status = "3";
      codigo_direccion = 1;
    }

    const direccionActual = document.getElementById('direccion-actual');
    direccionActual.textContent = this.state.step[n].description;
    //Select appropriate progress bar
    const selectBar = document.getElementById("progress-bar-first-leg");

    //Move progress bar around
    let factor = Math.min(Math.round(((n + 1) / (this.state.step.length)) * 100), 100);
    selectBar.style.width = `${factor}%`;
    selectBar.setAttribute('aria-valuenow', factor);
    selectBar.innerText = `${factor}%`;
  }

  finishValidation = () => {
    let lomitoUser = JSON.parse(window.localStorage.getItem('lomitoUser'));
    follow_validation.follow_step[this.state.step.length - 2].status = "1"
    follow_validation.follow_step[this.state.step.length - 1].status = "1"
    const selectBar = document.getElementById("progress-bar-first-leg");
    const data = {
      team_id: this.state.team_id,
      tour_id: this.state.tour_id,
      path_index: this.state.path_index,
      start: {
        description: this.state.start.description,
        heading: this.state.start.heading.toString(),
        lat: this.formatCoords(this.state.start.lat),
        lng: this.formatCoords(this.state.start.lng),
        indoor: this.state.start.indoor
      },
      end: {
        description: this.state.end.description,
        heading: this.state.end.heading.toString(),
        lat: this.formatCoords(this.state.end.lat),
        lng: this.formatCoords(this.state.end.lng),
        indoor: this.state.end.indoor
      },
      step: this.state.step.map(el => {
        return ({
          description: el.description,
          heading: el.heading.toString(),
          lat: this.formatCoords(el.lat),
          lng: this.formatCoords(el.lng),
          indoor: el.indoor
        });
      }),
      follow_validation: follow_validation
    };

    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json" //Aguas con esto, es super importante
      },
      body: JSON.stringify({data, user: lomitoUser, points: points})
    };
    //Enviar primero a backend y luego a Google
    fetch(`${SERVER_HOST}/api/validate-path`, options)
      .then(res => {
        const currentQuestionName = JSON.parse(window.localStorage.getItem('question')).questionName;
        const worker = JSON.parse(window.localStorage.getItem('datacomputeWorker'))

        window.gapi.client //Google
          .request({
            'path': `https://datacompute.googleapis.com/v1/${
              this.formatURL(currentQuestionName)}:submitAnswer?`,
            'method': 'POST',
            'body': {
              'userName': worker.result.name,
              'clientAnswerData': {
                'answerData': btoa(JSON.stringify(data))
              }
            }
          })
          .then(resp => {
            // console.log(resp);
            if (resp.result['name']) {  // Means response was successful.
              this.removeQuestionFromLeases(currentQuestionName);
              res.json().then(obj => {
                mySwal.fire({
                  title: "¡Lo lograste!",
                  text: "Ahora tu lomito es más feliz.",
                  imageUrl: randomLomito,
                  imageHeight: 200
                })
                  .then(() => {
                    tourAudio.play();
                    points = 0;
                    navigate("/app/paseo-success");
                  })
              })
                .catch(err => console.log(err));
            }
          }).catch(err => console.log(err));
      })
      .catch(err => console.log(err));


    selectBar.style.width = `100%`;
    selectBar.setAttribute('aria-valuenow', 100);
    selectBar.innerText = `100%`;
  }

  /*
  Aquí se está enviando la dirección, pero mejor acumular las 5 y enviar todo el path
  cuando esté completo
  */

  mostrarDirecciones = () => {
    let text = this.state.step.map((el, i) => `${i + 1}. ${el.description}`).reduce((ac, cv) => ac + cv + '\n', "")
    mySwal.fire({
      title: "Direcciones",
      text: text,
      imageUrl: randomLomito,
      imageHeight: 200
    });
  }

  //TODO: Componer esto
  getNewCoords = () => {
    mySwal.fire({
      title: 'Nuevo paseo',
      text: "¿Quieres cambiar de paseo?",
      imageUrl: randomLomito,
      imageHeight: 200,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, dame uno nuevo',
      cancelButtonText: 'No, mejor me quedo aquí'
    }).then((result) => {
      if (result.value) {
        fetch(`${SERVER_HOST}/api/get-route`)
          .then(res => {
            res.json().then(tour => {
              this.setState({ tour }); //Obtiene dirección aleatoria
              document.getElementById('direccion-actual').textContent = tour.step[0].description;
            })
              .catch(err => console.log(err));
          })
          .catch(err => console.log(err));
      }
    })

  }

  render() {
    return (
      <div>
        <Paseo {...this.state}
          addPoints={this.boneFound}
          changePanorama={e => this.handleLocation(e)}
          pickDirection={e => this.pickDirection(e)}
          finishValidation={this.finishValidation}
          newCoords={this.getNewCoords}
          heading={this.state.heading}
        />
        {/* Progress bars */}
        <div className="progress-container">
          <div className="progress" style={{ height: pbHeight }}>
            <div id="progress-bar-first-leg" className="progress-bar bg-info" role="progressbar" style={{ width: '5%', height: pbHeight }} aria-valuenow="5" aria-valuemin="0" aria-valuemax="100">5%</div>
          </div>
        </div>
        {/* Compartimentar dashboard */}
        <div className="dashboard">
          <div className="lomito">
            <img
              src={`/Assets_Lomito${img_sel}Crop.png`}
              alt="Lomito suavecito" />
          </div>
          <div id="direcciones-box" className="direcciones-box">
            <h4>Direcciones:</h4>
            <p id="direccion-actual" className="direccion-input"></p>
          </div>
          <div className="botones-dashboard">
            {/* <button className="btn-dashboard btn btn-secondary" onClick={this.mostrarDirecciones} type="button">Mostrar direcciones</button> */}
            <button onClick={this.instructionFailed} type="button" className="btn-dashboard btn btn-warning">No entendí la dirección</button>
            <button onClick={this.badLanguage} type="button" className="btn-dashboard btn btn-warning">Lenguaje ofensivo</button>
            <button onClick={this.instructionFailed} type="button" className="btn-dashboard btn btn-danger">¡Me rindo!</button>
          </div>
          <div className="huesitos-container">
            <h4>Pistas:</h4>
            <div className="huesitos" id="huesitos">
              {/* TODO: Hacer dinámico */}
              <img onClick={this.mostrarDirecciones}
                className="huesito-item"
                src="/huesito.svg"
                alt="Pistas" />
            </div>
          </div>
        </div>
        <form className="directions-form">
          <div className="coords-group">
            <label htmlFor="lat">Latitud</label>
            <input id="lat-input" disabled name="lat" type="text" value={this.state.lat} />
            <label htmlFor="lng">Longitud</label>
            <input id="lng-input" disabled name="lng" type="text" value={this.state.lng} />
            <label htmlFor="heading">Curso</label>
            <input id="heading-input" disabled name="heading" type="text" value={this.state.heading} />
          </div>
        </form>
      </div>
    );
  }
}

export default Validar;