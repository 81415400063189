import React from 'react'

function privacy() {
  return (
    <div>
      <h2>Política de privacidad</h2>
     <h3>¿Qué datos recopilamos?</h3>
Lomitocity recopila la siguiente información:

Información de identificación (nombre y correo electrónico)
Las palabras que se introducen en el juego.

<h3>
¿Cómo recopilamos tu información?
</h3>

A través del registro voluntario con tu cuenta de google.
Usando la app de lomitocity

<h3>
¿Cómo usaremos tus datos?
</h3>

Para tener un perfil guardado con tus datos y se guarden tus logros en el juego.
Enviar las palabras que introduces en el juego a terceros partidos, (sólo las palabras, no tu información personal, esa se enviará encriptada)
Al aceptar esta política de privacidad aceptas enviar los datos mencionados en el punto anterior.

<h3>
¿Cómo almacenamos tu información?
</h3>

Lomitocity guarda tu información en una instancia privada de mLab

<h3>
¿Cuáles son tus derechos de protección de datos?
</h3>

Derecho de acceso: Cada usuario tiene derecho a acceder a copias de sus datos personales.
Derecho de rectificación: Cada usuario tiene el derecho de solicitar rectificar a Lomitocity cualquier información que crea incorrecta. 
Derecho a borrar: Cada usuario tiene el derecho a borrar su información, bajo ciertas condiciones.
Derecho a restringir el procesamiento: Cada usuario tiene el derecho de restringir que procesen tus datos.
Derecho a portabilidad: Cada usuario tiene el derecho de transferir los datos que hayan recolectado a otra organización, bajo ciertas condiciones.
Si quisieres ejercer estos derechos contáctanos a lomitocity@gmail.com

Cookies: no utilizamos cookies

Cambios a nuestra política de privacidad:  Lomitocity puede efectuar cambios en nuestra política de privacidad. Esta política esta actualizada al día 28 de marzo de 2019. 
        
    </div>
  )
}

export default privacy
