import React from "react"
import StreetView from './StreetView';
import { connect } from 'react-redux'
import distance from 'haversine-distance'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import lomito1 from '../../assets/Assets_Lomito1Crop.png'
import lomito2 from '../../assets/Assets_Lomito2Crop.png'
import lomito3 from '../../assets/Assets_Lomito1Crop.png'

const randomLomito = [lomito1,lomito2,lomito3][Math.floor(Math.random()*3)]

const mySwal = withReactContent(Swal);
//TODO: Averiguar cómo implementar adecuadamente la numeración/serialización de las rutas.
//TODO: Arreglar los onclicks de los markers

let mapMarker = {
  path: `m 817.11249,282.97118 c -1.25816,1.34277 -2.04623,3.29881 -2.01563,5.13867 0.0639,3.84476 1.79693,5.3002 4.56836,10.59179 0.99832,2.32851 2.04027,4.79237 3.03125,8.87305 0.13772,0.60193 0.27203,1.16104 0.33416,1.20948 0.0621,0.0485 0.19644,-0.51262 0.33416,-1.11455 0.99098,-4.08068 2.03293,-6.54258 3.03125,-8.87109 2.77143,-5.29159 4.50444,-6.74704 4.56836,-10.5918 0.0306,-1.83986 -0.75942,-3.79785 -2.01758,-5.14062 -1.43724,-1.53389 -3.60504,-2.66908 -5.91619,-2.71655 -2.31115,-0.0475 -4.4809,1.08773 -5.91814,2.62162 z`,
  fillColor: `#ff4646`,
  fillOpacity: 1,
  scale: 1.33,
  strokeColor: `#d73534`,
  strokeWeight: 1
}

const Paseo = (props) => {
  const dogImage = {
    url: 'http://icons.iconarchive.com/icons/google/noto-emoji-animals-nature/256/22215-dog-icon.png',
    scaledSize: new window.google.maps.Size(50, 50), // scaled size
    origin: new window.google.maps.Point(0, 0), // origin
    anchor: new window.google.maps.Point(0, 0) // anchor
  };
  //const [currentPanorama, changePanorama] = useState(props); //Default is passed from InMap

  let coords, panorama, markers = [], dogs = [], grabbed = [];
  let waypointIndex = 1; //Index of marker for next step
  let refWaypoint = {
    lat: props.planRoute[waypointIndex].lat,
    lng: props.planRoute[waypointIndex].lng
  };
  const lengthForRender = 100;
  const lengthForSubmit = 25;
  const center = { lat: props.lat, lng: props.lng };

  const changeRefWaypoint = () => {
    waypointIndex++;
    if (waypointIndex < refWaypoint.length) {
      refWaypoint = {
        lat: props.planRoute[waypointIndex].lat,
        lng: props.planRoute[waypointIndex].lng
      };
    }
  }

  const renderDog = (coords, waypointIndex, map) => {
    let drift = 20; //Cuánto se permite al perrito moverse (en metros aprox)
    let dogDistance = 30; //in meters
    let refWaypoint = {
      lat: props.planRoute[waypointIndex].lat,
      lng: props.planRoute[waypointIndex].lng
    };
    let waypointDistance = distance(coords, refWaypoint);
    let lambda = dogDistance / waypointDistance; //Factor para dibujar al perrito
    let lngUncertainty = (Math.random() - 0.5) * (drift / 111139);
    let latUncertainty = (Math.random() - 0.5) * (drift / 111139);
    let dogPosition;
    if (waypointDistance > dogDistance) {
      dogPosition = {
        lat: (
          lambda * (refWaypoint.lat) + (1 - lambda) * (coords.lat) + latUncertainty
        ),
        lng: (
          lambda * (refWaypoint.lng) + (1 - lambda) * (coords.lng) + lngUncertainty
        )
      }
    }
    else {
      dogPosition = {
        lat: (
          (refWaypoint.lat + coords.lat) / 2
        ),
        lng: (
          (refWaypoint.lng + coords.lng) / 2
        )
      }
    }
    let dog = new window.google.maps.Marker({
      position: dogPosition,
      map,
      icon: dogImage //TODO: Usar diferentes perspectivas para la imagen
    });
    if (dogs.length > 0) {
      dogs[0].setMap(null);
      dogs.splice(0, 1);
    }
    dogs.push(dog);
    dogs[0].setMap(map);
  }

  const mapProps = {
    options: {
      center,
      zoom: 18,
    },
    //Ícono del inicio
    onMount: map => {
      let home = new window.google.maps.Marker({
        position: center,
        map,
        title: props.title
      })
      props.planRoute.map((marker, i) => {

        const center = { lat: marker.lat, lng: marker.lng }
        let mark = new window.google.maps.Marker({
          position: center,
          map,
          title: props.title,
        })
        grabbed.push(false);
        markers.push(mark);
        markers[i].setMap(null);
      })

      let endPoint = new window.google.maps.Marker({
        position: {
          lat: props.planRoute[props.planRoute.length - 1].lat,
          lng: props.planRoute[props.planRoute.length - 1].lng,
        },
        map,
        title: props.planRoute[props.planRoute.length - 1].description
      })
      markers.push(endPoint);
      markers[markers.length - 1].setMap(null);
      grabbed[0] = true;
      // markers[1].setMap(map)
      // markers.map((el, i) => {
      //   window.google.maps.event.addListener(el, 'click', () => {
      //     let myCoords = (panorama.getPosition() + '').split(',').map(el => el.replace(/[(\()|(\s)|(\\)))]/, '').replace(')', ""));
      //     if (distance({ lat: myCoords[0], lng: myCoords[1] }, refWaypoint) < lengthForRender) {

      //     }
      //   })
      // });

      panorama = map.getStreetView();
      const location = map.getCenter();

      const streetViewService = new window.google.maps.StreetViewService();
      streetViewService.getPanorama({
        location,
        source: 'outdoor', /* 
      Esto asegura que no tendrás photospheres ni panoramas raros.
      Checar: https://medium.com/compass-true-north/advanced-tips-for-using-google-maps-api-779bcd88858b
      */
      }, (pano, status) => {
        if (status === "OK") {
          const panoId = pano.location.pano;

          panorama.setPano(panoId);
          
          panorama.setPov(/** @type {google.maps.StreetViewPov} */({
            heading: props.heading,
            pitch: 0
          }));
          panorama.setVisible(true);
          panorama.addListener('position_changed', () => { //Callback to execute if pos changes
            // console.log("grabbed", grabbed) //TODO: Está muy raro esto
            //TODO: refactor
            //This is ugly but we can always refactor later 
            coords = (panorama.getPosition() + '').split(',').map(el => el.replace(/[(\()|(\s)|(\\)))]/, '').replace(')', ""));
            props.changePanorama({ lat: coords[0], lng: coords[1], heading: panorama.getPov().heading + '' });
            renderDog({ lat: coords[0], lng: coords[1] }, waypointIndex, map);
            markers.map((el, i) => {
              const distanceToWP = distance(
                { lat: coords[0], lng: coords[1] },
                { lat: el.position.lat(), lng: el.position.lng() }
              );
              
              if (distanceToWP < lengthForRender &&
                grabbed[i] === false) {
                markers[i].setMap(map);
                
              }
              if (distanceToWP < lengthForSubmit &&
                grabbed[i] === false &&
                i === waypointIndex && //TODO: ¿funciona?
                props.countStep() > 4) {
                grabbed[i] = true;
                setTimeout(
                  mySwal.fire({
                    title: "¡Super!",
                    text: '¡Llegaste al punto! Ahora por favor descríbelo brevemente.',
                    imageUrl: randomLomito,
                    imageHeight: 200
                  }).then(() => {
                    props.submitSteps();
                    if (!(grabbed.every(x => x === true))) {
                      changeRefWaypoint(); //TODO: Por aquí hay un error feo que habrá que corregir (ver en consola cuando se llega al punto y el perrito desaparece)
                    }
                  })
                  , 3000)
                
              }
              //Mostrar cada ícono si estás cerca
              if (i < (markers.length - 1) &&
                grabbed[i] === true) {
                markers[i].setMap(null);
                markers[i + 1].setMap(map);
                
              }
            });

          });
          panorama.addListener('pov_changed', () => {
            coords = (panorama.getPosition() + '').split(',').map(el => el.replace(/[(\()|(\s)|(\\)))]/, '').replace(')', ""));
            props.changePanorama({ lat: coords[0], lng: coords[1], heading: panorama.getPov().heading + '' });
          });
        }
        else {
          mySwal.fire({
            title: 'Ups!',
            text: 'No hay imágenes para tu punto A. ¿Quieres intentar en otro lugar?',
            type: 'error',
            imageUrl: randomLomito,
            imageHeight: 200
          }).then(() => {
            window.location.replace("/app/plan");
          })
        }
      })

    },
  }

  return (
    <div>
      <StreetView id="streetView" {...mapProps} />
    </div>
  );
}

export default connect(state => ({ planRoute: state.planRoute }))(Paseo);