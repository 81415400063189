import React from "react"
import { navigate } from "gatsby"
import { connect } from 'react-redux'

const PrivateRoute = ({ component: Component, location, auth,...rest }) => {
  if (!auth && location.pathname !== `/app/login`) {
    navigate(`/app/login`)
    return null
  }

  return <Component {...rest} />
}

export default connect(state => ({auth: state.auth}))(PrivateRoute)