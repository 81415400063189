import React, { Component } from "react";
import { navigate } from 'gatsby';
import './Tutorial.css';

export default class Tutorial extends Component {
  play = () => {
    navigate('/app/mode-select');
  }

  render () {
    return(
      <div className="tutorial">
      <iframe className="yt" width="560" height="315" src="https://www.youtube.com/embed/o17nsKwac9w" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      <br/>
      <button className="btn btn-outline-success" onClick={this.play}>¡Jugar!</button>
      </div>
    )
  }
}
