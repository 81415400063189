import React, { useState } from "react"
import StreetView from './StreetView';
import distance from 'haversine-distance';
import { connect } from 'react-redux'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import lomito1 from '../../assets/Assets_Lomito1Crop.png'
import lomito2 from '../../assets/Assets_Lomito2Crop.png'
import lomito3 from '../../assets/Assets_Lomito1Crop.png'

const randomLomito = [lomito1,lomito2,lomito3][Math.floor(Math.random()*3)]

const mySwal = withReactContent(Swal);
//SVG paths & styles para el huesito
let huesito = {
  path: `M35.45,6.656c-0.839-0.839-1.973-1.285-3.123-1.285c-0.37,0-0.74,0.049-1.104,0.142c0.387-1.491-0.032-3.116-1.144-4.227
  C29.222,0.428,28.093,0,26.966,0s-2.253,0.428-3.114,1.286c-1.568,1.571-1.686,4.034-0.388,5.757L7.078,23.503l0,0l-0.001,0.001
  c-0.785-0.597-1.724-0.892-2.662-0.892c-1.121,0-2.239,0.422-3.091,1.278c-1.718,1.718-1.718,4.51,0,6.228
  c0.839,0.84,1.973,1.285,3.122,1.285c0.37,0,0.74-0.049,1.104-0.142c-0.386,1.49,0.032,3.116,1.143,4.227
  c0.858,0.858,1.986,1.286,3.114,1.286c1.126,0,2.254-0.428,3.115-1.286c1.568-1.571,1.686-4.033,0.388-5.758l16.387-16.459
  l0.001-0.001c0.784,0.597,1.724,0.892,2.663,0.892c1.12,0,2.238-0.422,3.091-1.278C37.168,11.166,37.168,8.374,35.45,6.656z`,
  fillColor: 'white',
  fillOpacity: 1,
  scale: 1.33,
  strokeColor: 'black',
  strokeWeight: 2
}

let huesote = {
  path: `M35.45,6.656c-0.839-0.839-1.973-1.285-3.123-1.285c-0.37,0-0.74,0.049-1.104,0.142c0.387-1.491-0.032-3.116-1.144-4.227
  C29.222,0.428,28.093,0,26.966,0s-2.253,0.428-3.114,1.286c-1.568,1.571-1.686,4.034-0.388,5.757L7.078,23.503l0,0l-0.001,0.001
  c-0.785-0.597-1.724-0.892-2.662-0.892c-1.121,0-2.239,0.422-3.091,1.278c-1.718,1.718-1.718,4.51,0,6.228
  c0.839,0.84,1.973,1.285,3.122,1.285c0.37,0,0.74-0.049,1.104-0.142c-0.386,1.49,0.032,3.116,1.143,4.227
  c0.858,0.858,1.986,1.286,3.114,1.286c1.126,0,2.254-0.428,3.115-1.286c1.568-1.571,1.686-4.033,0.388-5.758l16.387-16.459
  l0.001-0.001c0.784,0.597,1.724,0.892,2.663,0.892c1.12,0,2.238-0.422,3.091-1.278C37.168,11.166,37.168,8.374,35.45,6.656z`,
  fillColor: 'yellow',
  fillOpacity: 1,
  scale: 1.8,
  strokeColor: 'black',
  strokeWeight: 2.2
}

const lengthForRender = 45; //Max es 50 metros, pero 45 funciona

const Paseo = (props) => {
  //const [currentPanorama, changePanorama] = useState(props); //Default is passed from InMap
  let panorama;
  let markers = [];
  let coords;
  const center = { lat: props.start.lat, lng: props.start.lng }
  const grabbed = Array(props.step.length).fill(false);
  const status = Array(props.step.length).fill(null);
  grabbed[0] = true;
  
  const mapProps = {
    options: {
      center,
      zoom: 18,
    },
    //Ícono del inicio
    onMount: map => {
      let home = new window.google.maps.Marker({
        position: center,
        map,
        title: props.start.description
      });

      //Set up markers for each of the bones, and hide them
      //This is so the markers can be revealed when the user is within
      //Sufficient distance
      //Sí funciona
      props.step.slice(0, props.step.length - 1).map((el, i) => {
        markers.push(new window.google.maps.Marker({
          position: { lat: el.lat, lng: el.lng },
          map,
          title: el.description,
          icon: huesito
        }));
        markers[i].setMap(null);
      })

      //TODO: Cambiar esto para que funcione con endpoint
      let endPoint = new window.google.maps.Marker({
        position: {
          lat: props.end.lat,
          lng: props.end.lng,
        },
        map,
        title: props.end.description,
        icon: huesote
      })
      markers.push(endPoint);
      markers[markers.length - 1].setMap(null); //Hide from map until within range

      panorama = map.getStreetView();
      const location = map.getCenter();

      const streetViewService = new window.google.maps.StreetViewService();
      streetViewService.getPanorama({
        location,
        source: 'outdoor', /* 
      Esto asegura que no tendrás photospheres ni panoramas raros.
      Checar: https://medium.com/compass-true-north/advanced-tips-for-using-google-maps-api-779bcd88858b
      */
      }, (pano, stat) => {
        if (stat === "OK") {
          const panoId = pano.location.pano;

          panorama.setPano(panoId);
          panorama.setPov(/** @type {google.maps.StreetViewPov} */({
            heading: props.heading || 0,
            pitch: 0
          }));

          panorama.setVisible(true);
          panorama.addListener('position_changed', () => { //Callback to execute if pos changes
            //TODO: refactor
            //This is ugly but we can always refactor later 
            coords = (panorama.getPosition() + '').split(',').map(el => el.replace(/[(\()|(\s)|(\\)))]/, '').replace(')', ""));
            props.changePanorama({ lat: coords[0], lng: coords[1], heading: panorama.getPov().heading + '' });
            markers.slice(0, markers.length).map((el, i) => {
              //Mostrar cada ícono si estás cerca
              if (distance({ lat: coords[0], lng: coords[1] }, { lat: el.position.lat(), lng: el.position.lng() }) < lengthForRender && grabbed[i] === false) {
                el.setMap(map);
              }
            });

          });
          panorama.addListener('pov_changed', () => {
            coords = (panorama.getPosition() + '').split(',').map(el => el.replace(/[(\()|(\s)|(\\)))]/, '').replace(')', ""));
            props.changePanorama({ lat: coords[0], lng: coords[1], heading: panorama.getPov().heading + '' });
          });

          markers.slice(0, markers.length).map((el, i, ar) => {
            if (i < ar.length - 1) {
              window.google.maps.event.addListener(el, 'click', () => {
                props.addPoints(); //Modificar para que dependa del tamaño del hueso
                el.setMap(null); //Borra el ícono del mapa
                status[i - 1] = 1;
                grabbed[i] = true;
                props.pickDirection(i);
              })
            }
            if (i === ar.length - 1) {
              window.google.maps.event.addListener(el, 'click', () => {
                props.addPoints(); //Modificar para que dependa del tamaño del hueso
                el.setMap(null); //Borra el ícono del mapa
                if (status[i-1]) {
                  status[i - 1] = 1;
                }
                if (status[i]) {
                  status[i] = 1;
                }
                markers.splice(0, markers.length);
                props.finishValidation();
              })
            }
          })
        } else {
          mySwal.fire({
            title: 'Ups!',
            text: 'Tendremos que reiniciar tu viaje. Una disculpa',
            type: 'error',
            imageUrl: randomLomito,
            imageHeight: 200
          }).then(() => {
            window.location.replace("/app/validar");
          })
        }
      }); 
        },
      }

  return (
        <div>
          <StreetView id="streetView" {...mapProps} />
          {/* <button id="btn-shuffle" className="btn btn-primary" onClick={props.newCoords}>
        <img src="https://static.thenounproject.com/png/50682-200.png" alt="Cambiar paseo" />
      </button> */}
        </div>
      );
    }

export default connect(state => ({ planRoute: state.planRoute }))(Paseo);