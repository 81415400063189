import React, { useState, useEffect } from "react"
import { navigate } from 'gatsby'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Paseo from '../components/Paseo/Paseo';
import 'bootstrap/dist/css/bootstrap.css'
import { connect } from 'react-redux'
import { CLEAR_MARKERS } from '../actions/types'
import './styles/dashboard.css'
import { modifyAssignment } from "../services/auth";
import lomito1 from '../assets/Assets_Lomito1Crop.png';
import lomito2 from '../assets/Assets_Lomito2Crop.png';
import lomito3 from '../assets/Assets_Lomito1Crop.png';
import boneSound from '../assets/Audio/bone.wav';
import boneSound2 from '../assets/Audio/bone2.wav';
import pathSound from '../assets/Audio/path.mp3';
import tourSound from '../assets/Audio/tour.mp3';

let boneAudio;
let boneAudio2;
let pathAudio;
let tourAudio;

if (typeof window !== 'undefined') {
  boneAudio = new Audio(boneSound);
  boneAudio2 = new Audio(boneSound2);
  pathAudio = new Audio(pathSound);
  tourAudio = new Audio(tourSound);
}

const randomLomito = [lomito1,lomito2,lomito3][Math.floor(Math.random()*3)]

const SERVER_HOST = "https://lomito-city-backend.herokuapp.com"
//const SERVER_HOST = "http://localhost:3000"
// console.log(process.env.SERVER_HOST); //TODO: ¿cómo vamos a resolver esto en deployment? ¿hardcode?

const mySwal = withReactContent(Swal);

const InMap = ({ planRoute, dispatch }) => {
  /* 
  
  This block handles the initial setup for the Google Street View Panorama.
  It feeds into Paseo, which in turn feeds into StreetView, both of which are
  components which are defined in the components folder.
  Changing this will change the props for Paseo, which will in turn change its
  state. Therefore, it will override any "immersion-driven" changes such as moving
  the panorama around in Street View.
  Therefore, it should not be altered except for special occasions:
    >When changing the location to a new random area.
    >When "redirecting" a lost user to the second or third leg of a tour.
  */

  const formatCoords = (coord) => {
    return (Math.round(coord * 10000000));
  }

  const { lat, lng } = planRoute[0]

  const [viewSetup, setView] = useState({ //Get from API / Query and change with react hooks
    tourID: 1, //Cambiar con la API; debe ser consecutivo para cada usuario único
    lat,
    lng,
    heading: 15.87,
    title: ""
  });
  /* 
  This array keeps the "steps" provided by users to construct directions from
  one endpoint to another within a path.
  Declared empty, but fed by the textbox within the page.
  */
  let path_index = 1; //Count up until the tour is done
  let img_sel = Math.floor(Math.random() * 3)+1;
  let currentWaypointName;
  const waypointNames = []; //Array to hold descriptions of each waypoint
  const descriptionHeading = []; //Array to hold headings
  const step = [];
  const pbHeight = '12px'
  const teamID = "3973891077675672561";
  let tourID = 0;

  const assignPathCreationQuestion = () => { //Call from within Paseo
    const worker = JSON.parse(window.localStorage.getItem('datacomputeWorker'))
    const publicName = worker.result.name;
    const assignments = worker.result.assignments;
    const typeToAssign = 'workspaces/mx_spatial_navigation/question_types/spatial_language_path_creation' //TODO: Change for deployment

    const typesToRemove = (!assignments || !assignments.questionTypes) ?
      [] :
      assignments.questionTypes.filter(el => el != typeToAssign);

    modifyAssignment(publicName, [typeToAssign], typesToRemove)
      .then(resp => { //Asignar pregunta
        window.gapi.client.datacompute.workspaces.leaseQuestions({ 'workerName': publicName })
          .then(res => {
            const { leases } = res.result;
            //Almacenar info de pregunta en localstorage
            window.localStorage.setItem('question', JSON.stringify(leases[leases.length - 1]))
          })
          .catch(err => {
            mySwal.fire({
              title: "Ups",
              text: "No pudimos asignarte este paseo, por favor escríbenos a lomito.city@gmail.com.",
              type: "error",
              imageUrl: randomLomito,
              imageHeight: 200
            })
          })

        //Actualiza assignments de worker en localstorage
        window.gapi.client.datacompute.worker_pools.workers
          .get({ 'name': publicName, 'includeAssignments': true })
          .then((resp) => {
            window.localStorage.setItem("datacomputeWorker", JSON.stringify(resp));
          });
      })
  }

  assignPathCreationQuestion();
  fetch(`${SERVER_HOST}/api/tour-count`)
  .then((resp) => {
    resp.json().then(tourCount => {
      tourID = (tourCount.count).toString();
    })
  })
  .catch(err => console.log(err));

  const nameWaypoint = () => {
    mySwal.fire({
      title: "Personaliza tu paseo...",
      text: 'Tómate unos momentos para mirar a tu alrededor con tu mouse o pantalla... ¿qué te llama la atención? ¿Cómo describiremos tu entorno?',
      imageUrl: randomLomito,
      imageHeight: 200
    }).then(() => {
      setTimeout(() => {
        mySwal.fire({
          title: "Describir",
          text: '¿Qué te pareció? ¿Puedes describir brevemente el entorno? Ej: "Fuente de la Diana", "Calles de Polanco", "Residencias estudiantiles", etc.',
          input: 'text',
          imageUrl: randomLomito,
          imageHeight: 200

        }).then(result => {
          if (result.value) {
            waypointNames.push(result.value)
            currentWaypointName = result.value
            document.getElementById('waypoint-name').textContent = currentWaypointName;
            descriptionHeading.push(document.getElementById('heading-input').value);
          }
          else {
            waypointNames.push(viewSetup.title);
            descriptionHeading.push(0);
          }
        })
      }, 8000)
    })
  }

  nameWaypoint()

  const countStep = () => {
    return (step.length);
  }

  /* 
  Here, we handle the variables inherent to the "immersion-driven" changes
  in the Street View Panorama. This is currently used for feedback for the
  developers, but will eventually be used to send POST requests to the
  backend, so that new clues and specific locations related to the gamified
  experience are recorded.
  */
  /* Called from within Paseo */
  /* These are manual changes, so the state doesn't make the component render
  again all over*/
  const handleLocation = (params) => {
    document.getElementById('lat-input').value = params.lat;
    document.getElementById('lng-input').value = params.lng;
    document.getElementById('heading-input').value = params.heading;
  }

  const finishTour = () => {
    navigate("/app/paseo-success");
  }

  /*
  Aquí se está enviando la dirección, pero mejor acumular las 5 y enviar todo el path
  cuando esté completo
  */


  const validate = description => { //function to validate
    const swalMessage = {
      title: '¡Oh, no!',
      text: 'Algo está mal en su descripción. Por favor cheque la ortografía de las palabras y intente otra vez',
      imageUrl: randomLomito,
      imageHeight: 200,
      animation: false
    }
    if (/(\w)\1{2,}/gi.test(description)) return swalMessage //checking if the same letter appears more than three consecutive times (invalid input)
    if (/(?<=\s|^)[^aeiouyíéáóú0-9\s]+(?=\s|$)/gi.test(description)) return swalMessage //checking for words with no vowels (invalid input)
    if (/[qwrtpsdfghjklñzxcvbnm]{5,}/gi.test(description)) return swalMessage //checking for 5+ consecutive consonants (invalid input)
    if ((description.split(/\s+/) || []).length < 5) { //checking if the description has at least five words
      swalMessage.text = 'El perrito necesita más información. Las direcciones deben de tener un mínimo de 5 palabras'
      return swalMessage
    }
    if (description.length > 300) { //checking if the description is too long
      swalMessage.text = 'El perrito no tiene muy buena memoria. Evita direcciones con más de 300 caracteres'
      return swalMessage
    }
    if (/\bsur\b|\bnorte\b|\boeste\b/gi.test(description)) { //checking for forbidden words
      swalMessage.text = 'El perrito no tiene brújula. Evita palabras como "norte", "sur", "este" u "oeste"'
      return swalMessage
    }
    else return true
  }

  const enviar = () => {
    if (Math.floor(Math.random() * 2) === 1) {
      boneAudio.play();
    }
    else {
      boneAudio2.play();
    }
    const lat = document.getElementById('lat-input').value;
    const lng = document.getElementById('lng-input').value;
    const heading = document.getElementById('heading-input').value;
    const description = document.getElementById('direcciones').value;
    if (validate(description) !== true) { // form validations
      Swal.fire(validate(description))
      return;
    }

    const newStep = {
      lat: formatCoords(lat),
      lng: formatCoords(lng),
      heading: (Math.round(heading)).toString(),
      description: description,
      indoor: false
    };

    // console.log(newStep);
    step.push(newStep);
    // console.log(step);

    //Select appropriate progress bar
    const selectBar = (path_index === 1) ?
      document.getElementById("progress-bar-first-leg") :
      (
        (path_index === 2) ? document.getElementById("progress-bar-second-leg") : document.getElementById("progress-bar-third-leg")
      )

    //Move progress bar around
    let factor = Math.min(Math.round((step.length / 5) * 100), 100);
    selectBar.style.width = `${factor}%`;
    selectBar.setAttribute('aria-valuenow', factor);
    selectBar.innerText = `${factor}%`;

    //Enable button if disabled
    document.getElementById('direcciones').value = '';
  }

  const mostrarDirecciones = () => {
    Swal.fire({
      title: 'Ahí vamos por ahora:',
      text: step.map((el, i) => `${i + 1}. ${el.description}`).reduce((ac, cv) => ac + cv + '\n', ""),
      imageUrl: randomLomito,
      imageHeight: 200,
      animation: false
    })
  }

  const formatURL = (url) => {
    return url.replace(/\+/g, '%2B');
  }

  const submitSteps = () => {
    if (step.length >= 5) {
      mySwal.fire({
        input: 'text',
        title: 'Describe tu alrededor',
        text: '¿Qué descripción mostraremos de tus alrededores a otros usuarios? Ej: "Entrada a Chapultepec", "World Trade Center", "Calles de la Col. Escandón" etc.',
        type: 'question',
        imageUrl: randomLomito,
        imageHeight: 200
      }).then(result => {
        if (result.value) {
          waypointNames.push(result.value)
          currentWaypointName = result.value
          document.getElementById('waypoint-name').textContent = currentWaypointName;
          descriptionHeading.push(document.getElementById('heading-input').value);
        }
        else {
          waypointNames.push(`${viewSetup.title} - Final`);
          descriptionHeading.push(0);
        }

        let worker = JSON.parse(window.localStorage.getItem('datacomputeWorker'))
        let lomitoUser = JSON.parse(window.localStorage.getItem('lomitoUser'));
        // let pathQuant = lomitoUser.pathsCreated.length;
        // let tourID = `${lomitoUser.authId}${pathQuant.toString().padStart(4, 0)}`; //TODO: está bien?
        let currentQuestionName = JSON.parse(window.localStorage.getItem('question')).questionName;

        const data = {
          team_id: teamID,
          tour_id: tourID,
          path_index: path_index,
          start: {
            lat: formatCoords(planRoute[path_index - 1].lat),
            lng: formatCoords(planRoute[path_index - 1].lng),
            heading: (Math.round(descriptionHeading[path_index - 1])).toString(),
            description: waypointNames[path_index - 1],
            indoor: false
          },
          end: {
            lat: formatCoords(planRoute[path_index].lat),
            lng: formatCoords(planRoute[path_index].lng),
            heading: (Math.round(descriptionHeading[path_index])).toString(),
            description: waypointNames[path_index],
            indoor: false
          },
          step: [...step] //Por algún motivo aquí no queda bien step. Parece en blanco, aunque Mongo sí lo recibe bien
        };
        // console.log("Formed data", data)

        let options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json" //Aguas con esto, es super importante
          },
          body: JSON.stringify({data, user: lomitoUser})
        };

        // console.log("Request:" )
        // console.log({
        //   'path': `https://datacompute.googleapis.com/v1/${
        //     formatURL(currentQuestionName)}:submitAnswer?`,
        //   'method': 'POST',
        //   'body': {
        //     'userName': worker.result.name,
        //     'clientAnswerData': {
        //       'answerData': btoa(JSON.stringify(data))
        //     }
        //   }
        // });

        //Primero enviar a backend, después a Google.
        fetch(`${SERVER_HOST}/api/create-path`, options) //Backend
          .then((res) => {
            res.json()
              .then(msg => {
                window.gapi.client //Google
                  .request({
                    'path': `https://datacompute.googleapis.com/v1/${
                      formatURL(currentQuestionName)}:submitAnswer?`,
                    'method': 'POST',
                    'body': {
                      'userName': worker.result.name,
                      'clientAnswerData': {
                        'answerData': btoa(JSON.stringify(data))
                      }
                    }
                  })
                  .then(resp => {
                    // console.log(resp);
                    if (resp.result['name']) {  // Means response was successful.
                      removeQuestionFromLeases(currentQuestionName);
                      currentQuestionName = undefined;
                      mySwal.fire({
                        title: "¡Tu ruta fue enviada!",
                        text: msg.message,        
                        imageUrl: randomLomito,
                        imageHeight: 200
                      })
                        .then(() => {
                          pathAudio.play();
                          // Clear all directions
                          step.splice(0, step.length)
                          path_index++;
                          if (path_index === planRoute.length) {
                            tourAudio.play();
                            finishTour()
                          }
                          else {
                            const selectBar = (path_index === 2) ? document.getElementById("progress-bar-second-leg") : document.getElementById("progress-bar-third-leg");
                    
                            if (path_index < 4) {
                              selectBar.style.width = `5%`;
                              selectBar.setAttribute('aria-valuenow', 5);
                              selectBar.innerText = `5%`;
                            }
                            assignPathCreationQuestion();
                          }
                        })
                        .catch(err => console.log(err));
                    }
                  });
              })
              .catch(err => console.log(err));
          }).catch(err => console.log(err));

      });
    }
    else {
      mySwal.fire({
        title: "Direcciones",
        text: `Necesitas enviar por lo menos 5 direcciones para que el lomito no se pierda`,
        type: "warning",
        imageUrl: randomLomito,
        imageHeight: 200
      });
    }
  }

  const removeQuestionFromLeases = () => {
    window.localStorage.removeItem('question');
  }

  useEffect(() => {
    return () => {
      dispatch({ type: CLEAR_MARKERS })
    }
  })

  return (
    <div>
      <Paseo {...viewSetup}
        changePanorama={e => handleLocation(e)}
        submitSteps={submitSteps}
        countStep={countStep}
      />
      {/* Progress bars */}
      <div className="progress-container">
        <div className="progress" style={{ height: pbHeight }}>
          <div id="progress-bar-first-leg" className="progress-bar bg-info" role="progressbar" style={{ width: '5%', height: pbHeight }} aria-valuenow="5" aria-valuemin="0" aria-valuemax="100">5%</div>
        </div>
      </div>
      <div className="progress-container">
        <div className="progress" style={{ height: pbHeight }}>
          <div id="progress-bar-second-leg" className="progress-bar" role="progressbar" style={{ width: '0%', height: pbHeight }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">0%</div>
        </div>
      </div>
      <div className="progress-container">
        <div className="progress" style={{ height: pbHeight }}>
          <div id="progress-bar-third-leg" className="progress-bar bg-success" role="progressbar" style={{ width: '0%', height: pbHeight }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">0%</div>
        </div>
      </div>
      {/* Compartimentar dashboard */}
      <div className="dashboard">
        <div className="lomito">
          <img
            src={`/Assets_Lomito${img_sel}Crop.png`}
            alt="Lomito suavecito" />
        </div>
        {/* <div className="btn-container">
          <button className="btn btn-primary" onClick={getNewCoords}>Quiero otro lugar</button>
        </div> */}
        <div className="form-container">
          <form className="directions-form">
            <div className="coords-group">
              <label htmlFor="lat">Latitud</label>
              <input id="lat-input" disabled name="lat" type="text" value={viewSetup.lat} />
              <label htmlFor="lng">Longitud</label>
              <input id="lng-input" disabled name="lng" type="text" value={viewSetup.lng} />
              <label htmlFor="heading">Curso</label>
              <input id="heading-input" disabled name="heading" type="text" value={viewSetup.heading} />
            </div>
            <div className="direcciones-box">
              <label htmlFor="direccion">Direcciones:</label> <br />
              <textarea id="direcciones" className="direccion-input" rows="3" cols='30' name="direccion" autoComplete='off' /> <br />
              <button className="btn btn-primary" onClick={enviar} type="button">Enviar dirección</button>
              <button className="btn btn-secondary" onClick={mostrarDirecciones} type="button">Mostrar direcciones</button>
            </div>
          </form>
        </div>
        <div className="info-paseo">
          <h3>Estás en...</h3>
          <h4 id="waypoint-name">{viewSetup.title}</h4>
          {/* Éste sólo se debe habilitar cuando ya mandó 5 direcciones */}
          {/* <button
            id="btn-submitpath"
            className="btn btn-outline-success"
            type="button"
            onClick={e => submitSteps(e)}>¡Ya llegamos!</button> */}
          {/* Redireccionar jugador a punto siguiente, o dar opción 
          para terminar el tour */}
          {/* TODO: Quitar botón de Me rindo */}
          {/* <button type="button" className="btn btn-outline-warning">Me rindo :(</button> */}
        </div>
      </div>
    </div>
  );
}

export default connect(state => ({ planRoute: state.planRoute }))(InMap);