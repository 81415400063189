import React, { Component } from 'react'
import PlanRoute from './PlanRoute/PlanRoute';
import './PlanRoute/Plan.css'
import { Link } from 'gatsby'

let currentMarkers = 0;

class Plan extends Component {
  state = {
    currentMarkers : 0,
    markers : []
  }

  //TODO: Encontrar una solución más elegante para esto
  eraseMarkers = () => {
    document.location.reload();
  }

  appendMarker = (marker) => {
    this.state.markers.push(marker)
    this.setState({currentMarkers: (this.state.currentMarkers + 1)})
    document.getElementById('mark').textContent = this.state.currentMarkers;
  }

  mapMarkersToPosition = () => {
    return this.state.markers.map((el, i) => {
      return ({lat: el.position.lat(), lng: el.position.lng()});
    });
  }

  render () {
    return (
      <div className="planeacion">
        <PlanRoute lat={19.423593} lng={-99.174968} 
        appendMarker={e => this.appendMarker(e)} />
        <h3 className="instrucciones">Haz doble clic en el mapa para colocar lugares a
        los que te gustaría ir</h3>
        <h4>Escoge entre 4 y 6 puntos, por favor...</h4>
        <p className="par-instrucciones">Cada punto debe estar a más de 200 metros y menos de 1000 metros del anterior.</p>
        <div className="marker-counter">
          <span className="texto-counter">Ahorita llevas <em id="mark">0</em> puntos</span>
        </div>
        <div className="buttons-control">
          {/* <button style={{margin: `auto 1vw`}} className="btn btn-outline-info" type="button" onClick={this.eraseMarkers}>Borrar direcciones</button> */}
          {
            this.state.currentMarkers > 3 &&
            <Link to='/app/mapa'><button style={{margin: `auto 1vw`}} className="btn btn-outline-success" type="button">¡Iniciar ruta!</button></Link>
          }
        </div>
      </div>
    )
  }
}

export default Plan;