import React, { Component } from 'react';
import { navigate } from 'gatsby'
import './PaseoSuccess.css';

class PaseoSuccess extends Component {
  render () {
    return (
      <div className="success-msg">
        <h2>¡Excelente!</h2>
        <p>
          Gracias a ti, otros lomitos podrán navegar mucho mejor
          las calles de la Ciudad de México.
        </p>
        <button className="btn btn-outline-info"
        onClick={() => navigate('/app/mode-select')}
        >¿Otra vuelta?</button>
      </div>
    )
  }
}

export default PaseoSuccess;