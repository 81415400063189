import React from "react";
import distance from 'haversine-distance';
import Map from './Map';
import { connect } from 'react-redux'
import { ADD_MARKER } from '../../actions/types'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import lomito1 from '../../assets/Assets_Lomito1Crop.png'
import lomito2 from '../../assets/Assets_Lomito2Crop.png'
import lomito3 from '../../assets/Assets_Lomito1Crop.png'

const randomLomito = [lomito1,lomito2,lomito3][Math.floor(Math.random()*3)]

const mySwal = withReactContent(Swal);
let countMarkers = 0;

const PlanRoute = (props) => {
  //const [currentPanorama, changePanorama] = useState(props); //Default is passed from InMap

  let panorama;
  let coords;
  const center = { lat: props.lat, lng: props.lng }
  const markers = [];
  const circles = [];

  //Ícono del inicio
  const addCircle = (map, location) => {
    let innerCircle = new window.google.maps.Circle({
      strokeColor: '#FF0000',
      strokeOpacity: 0.7,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
      map: map,
      center: location,
      radius: 200
    });

    circles.push(innerCircle);

  }

  // const zoomMap = (map, location) => {
  //   const coords = { lat: location.lat(), lng: location.lng() }
  //   m
  // }

  const addMarker = (map, location) => {
    const alpha = "ABCDEFGHIJKLMNOPQRSTUVWXYZABCDEFGHIJKLMNOPQRSTUVWXYZABCDEFGHIJKLMNOPQRSTUVWXYZABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let marker = new window.google.maps.Marker({
      position: location,
      map: map,
      label: alpha[countMarkers]
    });
    props.appendMarker(marker);
    markers.push(marker);
    countMarkers++;
    const coords = { lat: location.lat(), lng: location.lng() }
    props.dispatch({type: ADD_MARKER, payload: coords})
  },


  mapProps = {
    options: {
      center,
      zoom: 14,
      disableDoubleClickZoom : true
      // gestureHandling : "cooperative"
    },

    onMount: map => {
      map.addListener('dblclick', (e) => {
        if (markers.length > 0) {
          let point1 = {lat: markers[markers.length-1].position.lat(),
            lng: markers[markers.length-1].position.lng()};
          let point2 = {lat: e.latLng.lat(), lng: e.latLng.lng()};
          let diff = distance(point1, point2);
          // console.log(diff);
          if (markers.length < 6 &&
            diff > 200 &&
            diff < 1000) {
              addMarker(map, e.latLng);
              addCircle(map, e.latLng);
            }
            else if (diff >= 1000) {
              mySwal.fire({
                title: "Demasiado lejos...",
                text: `Tu lomito se va a cansar mucho si vas tan lejos sin descansar... 
                mejor escoge un punto medio que esté a menor distancia del anterior (menos de 1km)`,
                imageUrl: randomLomito,
                imageHeight: 200
              });
            }
            else if (diff < 200) {
              mySwal.fire({
                title: "Demasiado cerca...",
                text: `Tu lomito quiere pasear un poco más... 
                mejor escoge un punto medio que esté a menor distancia del anterior (más de 200m)`,
                imageUrl: randomLomito,
                imageHeight: 200
              });
            }
            else if (markers.length === 6) {
              mySwal.fire({
                title: "Suficientes puntos...",
                text: `Ya tenemos suficientes puntos a recorrer... ¡Es hora de pasear!`,
                imageUrl: randomLomito,
                imageHeight: 200
              });
            }
          }
        else {
          addMarker(map, e.latLng);
          addCircle(map, e.latLng);
          map.panTo(e.latLng);
          map.setZoom(16);
        }          
        });
    },
  }

  return (
    <div>
      <Map id="streetView" {...mapProps} />
    </div>
  );
}

export default connect(state => ({planRoute: state.planRoute}))(PlanRoute);