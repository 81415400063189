import React from 'react'
import { Router,  } from '@reach/router'
import Layout from '../components/layout'
import Profile from '../components/profile'
import Login from '../components/login'
import PrivateRoute from '../components/PrivateRoute'
import {Tos, Tutorial, Privacy, Play, Plan, Echo, ModeSelect, Research, PaseoSuccess, InMap, Validar, Test, Dashboard} from '../components'
import './androidFix.css'

const App = () => {
  return (
    <Layout>
      <Router>
        <Tos  path='/app/tos'/>
        <Play path='/app/play'/>
        <Login  path='/app/entrar' />
        <Privacy  path='/app/privacidad'/>
        <Plan path='/app/plan'/>
        <ModeSelect path='/app/mode-select'/>
        <PaseoSuccess path='/app/paseo-success'/>
        <InMap path='/app/mapa'/>
        <Echo path='/app/echo'/>
        <Validar path='/app/validar'/>
        <Research path='/app/research'/>
        <Test path='/app/test'/>
        <Tutorial path='/app/tutorial'/>
        <PrivateRoute path='/app/menu' component={Dashboard}/>
      </Router>
    </Layout>
)}

export default App