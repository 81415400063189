import React, { useEffect } from 'react'
import { handleLogin, getUserFromBackend, createUserInBackend, mergeUser } from '../services/auth'
import { connect } from 'react-redux'
import { navigate, Link } from 'gatsby'
import SEO from './seo'

const handleSetSession = dispatch => {
  const { GoogleAuth } = window
  GoogleAuth.signIn()
    .then(user => {
      handleLogin(user)
      getUserFromBackend(user)
        .then(res => {
          res.json()
          .then(backendUser => {
            if ((backendUser !== null) && backendUser['_id']) {
              window.localStorage.setItem('lomitoUser', JSON.stringify(backendUser));
              mergeUser(backendUser)
              // console.log('dispatch')
              //dispatch({ type: MERGE_USER, payload: backendUser})
              //guardar al usuario en el state
              //TODO: Joss pasa usuario a redux
              //console.log(backendUser)
              if (backendUser.tutorial) {
                navigate('/app/mode-select');
                return;
              }
              else {
                navigate('/app/tutorial'); //TODO: Cuando el usuario termine el tutorial marcar que ya lo completó
                return;
              }
            } else {
              createUserInBackend(user)
              .then((res) => {
                res.json().then(newUser => {
                  window.localStorage.setItem('lomitoUser', JSON.stringify(newUser));
                  navigate('/app/tutorial');
                  // return;
                })
                .catch(err => console.log(err));
              })
              .catch(err => {
                //TODO: Handle error if user creation fails
              })
            }
          })
          .catch(err => console.log(err));
        })
        .catch(err => console.log(err));
    })
    .catch(console.error)
}

const play = ({ auth }) => {

  useEffect(() => {
    if (auth) {
      navigate('/app/mode-select')
    }
  }, [])

  return (
    <div>
      <SEO title='Jugar' keywords={[`lomitocity`, `pasea a tu lomito`, `react`]} />
      <h2>¿Quieres jugar?</h2>
      <p> <Link className="liga-politicas" to="/app/tos">Términos de Servicio</Link> </p>
      <p> <Link className="liga-politicas" to="/app/privacidad">Política de privacidad</Link> </p>
      <button className="btn btn-outline-success" onClick={handleSetSession}>Entrar</button>
    </div>
  )
}

export default connect(state => ({ auth: state.auth }))(play)