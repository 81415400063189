import React, { Component } from 'react';

export default class Research extends Component {
  render() {
    return (
      <div className="research">

<h2>Lomito City</h2>

<strong>Lomito City</strong> es un juego donde puedes crear paseos o seguir paseos para perritos virtuales. En el juego podrás crear paseos increíbles para pasear a tu lomito en Google Street View y también podrás descubrir los paseos que hicieron para otros lomitos.



<h3>Investigación de lenguaje</h3>

Las descripciones de texto que uses para elaborar paseos se recolectarán para aportarlos a una investigación en el área de “entendimiento de lenguaje espacial” con modelos de <em>machine learning</em>.
      <br/>

  <a href="https://spatial-language.github.io/old_SpLU_workshops/SpLU_2018/" target="window">Aprender más</a>
      </div>
    )
  }
};