import React, {  } from 'react'

// var QUESTION_TYPE = 'workspaces/mx_spatial_navigation/question_types/dev_spatial_language_path_creation'

// var GoogleAuth;
// var publicName;
// // var questionLeases;
// // var currentQuestionName;

// const getUserContext = async () => {
//   const resp = await window.gapi.client.datacompute.getUserContext()
//   publicName = getPublicName(resp.result)
//   if (publicName) {
//     console.log(publicName)
//     return getWorker(publicName)
//   }
//   createPublicWorker()
// }

// const getPublicName = userContextResponse => {
//   if(!userContextResponse.identities) return
//   let identity = userContextResponse.identities.find((identity) => {
//     return identity.parentId === 'public'
//   })
//   return identity ? identity['name'] : undefined
// }

// const getWorker = async publicName => {
//   const resp = await window.gapi.client.datacompute.worker_pools.workers
//       .get({'name': publicName, 'includeAssignments': true})
//   checkAndAssign(resp.result)
// }

// const createPublicWorker = async languages => {
//   let email = GoogleAuth.currentUser.get().getBasicProfile().getEmail()
//   const resp = await window.gapi.client.datacompute.worker_pools.workers
//     .create(
//       {'parent': 'worker_pools/public'},
//       {'emailAddress': email, 'capabilities': {'languageCodes': languages}})
//   checkAndAssign(resp.result)
//   publicName = resp.result['name']
// }

// const updateWorkerLanguages = async (workerName, languageCodes) => {
//   const resp = await window.gapi.client.datacompute.worker_pools.workers.patch({
//     'name': workerName,
//     'capabilities': {'languageCodes': languageCodes}
//   })
//   if(resp) {
//     // Handle worker update. Empty for now.
//   }
// }

// const checkAndAssign = (workerResult) => {
//   if (!hasWorkerAssignment(workerResult, QUESTION_TYPE)) {  // REPLACE.
//     modifyAssignment(workerResult['name'], [QUESTION_TYPE]);
//   }
//   // Worker needs languageCode capabilities that match the question.
//   if (!workerResult['capabilities']['languageCodes']) {
//     updateWorkerLanguages(workerResult.name, ['en']);
//   }
// }

// const hasWorkerAssignment = (workerResult) => {
//   if (!workerResult.assignments || !workerResult.assignments.questionTypes)
//     return false;
//   return workerResult.assignments.questionTypes.includes(QUESTION_TYPE);
// }

// const modifyAssignment = async (workerName, questionTypesArray) => {
//   const resp = await window.gapi.client.datacompute.worker_pools.workers
//     .modifyAssignments(
//       {'name': workerName}, {'addedQuestionTypes': questionTypesArray})
//     if(resp) {
//       // Handle modification. Empty for now.
//     }
// }

// const getQuestions = async () => {
//   const resp = await window.gapi.client.datacompute.workspaces.leaseQuestions({'workerName': publicName})
//   if(resp) {
//     if (resp.result.leases) {
//       questionLeases = resp.result.leases;
//       answerQuestionFromLease(questionLeases[0]);
//     } else{
//     alert('Unable to lease questions!');
//     }
//   }
// }

// const answerQuestionFromLease = lease => {
//   currentQuestionName = lease.questionName;
//   getPayloadList(lease);
//   // $('#get-questions-button').css('display', 'none');
//   // $('#answer-block').css('display', 'block');
// }

// const getPayloadList = async lease => {
//   const resp = await window.gapi.client
//     .request({
//       'path': `https://content-datacompute.googleapis.com/v1/${
//         formatURL(currentQuestionName)}:listQuestionPayloads?pageSize=10`,
//       'method': 'GET',
//     })

//   if (resp.result.payloads) {
//     resp.result.payloads.forEach((payload) => {
//       getPayload(lease.questionId, payload['name'], currentQuestionName);
//     })
//   }
// }

// const formatURL = (url) => {
//   return url.replace(/\+/g, '%2B')
// }

// const getPayload = (questionId, payloadName) => {
//   window.gapi.client.request({
//     'path': `https://content.googleapis.com/crowdcomputeworker/v1/questions/0/${
//         payloadName}?alt=media&stringQuestionId=${
//         formatURL(questionId)}&workerPoolId=public`,
//     'method': 'GET',
//   });
// }

// const submitAnswer = async () => {
//   const resp = await window.gapi.client
//     .request({
//       'path': `https://datacompute.googleapis.com/v1/${
//           formatURL(currentQuestionName)}:submitAnswer?`,
//       'method': 'POST',
//       'body': {
//         'userName': publicName,
//         'clientAnswerData': {
//           'answerData': 'SGVsbG8gV29ybGQh'
//         }
//       }
//     })

//     if (resp.result['name']) {  // Means response was successful.
//       // $('#answer-json').val('');
//       removeQuestionFromLeases();
//       currentQuestionName = undefined;
//       // $('#get-questions-button').css('display', 'inline-block');
//       // $('#answer-block').css('display', 'none');
//     }

// }

// const removeQuestionFromLeases = () => {
//   let index = questionLeases.findIndex((lease) => {
//     return lease.questionName === currentQuestionName;
//   });
//   if (index !== -1) questionLeases.splice(index, 1);
// }


export default function Test() {

  // useEffect(()=>{
  //   setTimeout(()=>getUserContext(),400)
  // },[])

  return (
    <div>
      <button>create Worker</button>
    </div>
  )
}
