import React, { Component } from 'react'
import './StreetView.css';


export default class StreetView extends Component {
  onLoad = () => {
    const map = new window.google.maps.Map(
      document.getElementById(this.props.id),
      {...this.props.options,
        fullscreenControl: false},
    )
    this.props.onMount(map)
  }

  componentDidMount() {
      this.onLoad()
  }

  componentDidUpdate() {
    const map = new window.google.maps.Map(
      document.getElementById(this.props.id),
      {...this.props.options,
        fullscreenControl: false}
    )
    this.props.onMount(map);
  }

  render() {
    return <div className="street-view" id={this.props.id} />
  }
}