import React, { Component } from 'react';
import { modifyAssignment } from '../services/auth';
import boneSound from '../assets/Audio/bone.wav';
import boneSound2 from '../assets/Audio/bone2.wav';
import pathSound from '../assets/Audio/path.mp3';
import tourSound from '../assets/Audio/tour.mp3';
import BGMSound from '../assets/Audio/bgmusic.wav';

let boneAudio;
let boneAudio2;
let pathAudio;
let tourAudio;
let BGMAudio;

if (typeof window !== 'undefined') {
  boneAudio = new Audio(boneSound);
  boneAudio2 = new Audio(boneSound2);
  pathAudio = new Audio(pathSound);
  tourAudio = new Audio(tourSound);
  BGMAudio = new Audio(BGMSound);
}

class Echo extends Component {
  getEcho = () => {
    const workerName = JSON.parse(window.localStorage.getItem('datacomputeWorker')).result.name;

    //Get assignments
    window.gapi.client.datacompute.worker_pools.workers
      .get({ name: workerName, includeAssignments: true })
      .then(res => {
        // console.log(res);
        
        const assignments = res.result.assignments; //Destructure
        // console.log(assignments);
        const typeToAssign = 'workspaces/mx_spatial_navigation/question_types/spatial_language_path_echo';
        const typesToRemove = (!assignments || !assignments.questionTypes) ? [] : assignments.questionTypes.filter(el => el !== typeToAssign);
        // console.log(typeToAssign);
        // console.log(typesToRemove);
        //Set echo
        window.gapi.client.datacompute.worker_pools.workers
          .modifyAssignments(
            { 'name': workerName }, { 'addedQuestionTypes': typeToAssign, 'removedQuestionTypes': typesToRemove }
          )
          .then((resp) => {
            
            //Read question and display
            window.gapi.client.datacompute.workspaces.leaseQuestions({ 'workerName': workerName })
              .then(finalRes => {
                /*
                "workspaces/mx_spatial_navigation/question_types/dev_spatial_language_path_creation/questions/b0750a010339b8a2c00be22be3d6496e+mx_spatial_navigation+dev_spatial_language_path_creation+INTERNAL+en:16684688322246671163/answers/4339964416"
                */
                // console.log(finalRes);
              })
          });
      });
  }

  echoNoModify = () => {
    const workerName = JSON.parse(window.localStorage.getItem('datacomputeWorker')).result.name;

    //Get assignments
    window.gapi.client.datacompute.worker_pools.workers
      .get({ name: workerName, includeAssignments: true })
      .then(res => {
        // console.log(res);
        const assignments = res.result.assignments.questionTypes; //Destructure
        // console.log(assignments);

        //Read question and display
        window.gapi.client.datacompute.workspaces.leaseQuestions({ 'workerName': workerName })
          .then(finalRes => {
            /*
            "workspaces/mx_spatial_navigation/question_types/dev_spatial_language_path_creation/questions/b0750a010339b8a2c00be22be3d6496e+mx_spatial_navigation+dev_spatial_language_path_creation+INTERNAL+en:16684688322246671163/answers/4339964416"
            */
            // console.log(finalRes);
          })
      });
  }

  componentDidMount () {

  }

  testPath = () => {
    pathAudio.play()
  }

  testTour = () => {  
    tourAudio.play();
  }

  testBone1 = () => {
    boneAudio.play();
  }

  testBone2 = () => {

    boneAudio2.play()
  }

  testBGM = () => {
    if (BGMAudio.paused) {
      BGMAudio.play();
      BGMAudio.ontimeupdate = function(i) {
        if((this.currentTime / this.duration)>0.98){
          this.currentTime = 0;
          this.play();
        }
      };
    } else {
      BGMAudio.pause();
    }
  }

  render() {
    return (
      <div className="check">
        <div onClick={this.getEcho} className="btn btn-success">Echo</div>
        <div onClick={this.echoNoModify} className="btn btn-success">Echo sin modificacion</div>
        <div onClick={this.testPath} className="btn btn-success">Soundtest - Path</div>
        <div onClick={this.testTour} className="btn btn-success">Soundtest - Tour</div>
        <div onClick={this.testBone1} className="btn btn-success">Soundtest - Bone1</div>
        <div onClick={this.testBone2} className="btn btn-success">Soundtest - Bone2</div>
        <div onClick={this.testBGM} className="btn btn-success">Soundtest - BGM</div>
      </div>
    )
  }
}

export default Echo;