import React from "react"


const Login = () => {
    return (
      <>
        <h1>Log in</h1>

      </>
    )
  }

export default Login