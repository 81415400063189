import React from 'react'
import { connect } from 'react-redux'
import SEO from './seo'

const Dashboard = props => {
  return (
    <>
      <SEO title={props.auth.ofa}/>
      <h2>Nombre:</h2>
      <p>{props.auth.ofa}</p>
      <img src={props.auth.Paa} alt={`${props.auth.ofa} photo`}/>
      <h3>Puntos:</h3>
      <p>{JSON.parse(window.localStorage.getItem('lomitoUser')).score || 0}</p>
    </>
  )
}

export default connect(state => ({auth: state.auth}))(Dashboard)
