import React, { Component } from "react";
import { Link } from 'gatsby';
import "./ModeSelect.css"
import lomito1 from '../../assets/Assets_Lomito1.png'
import SEO from '../seo'

class ModeSelect extends Component {

  render() {
    return (
      <section className="modeSelect">
        <SEO title='Seleccion'/>
        <div className="topImages"><img src={lomito1} id="lomito1" alt="Lomito suavecito" srcSet=""/> </div>       
        <div className="modeButtons"><Link to='/app/plan'>
          <button className="btn btn-outline-success option-buttons">Crear un paseo</button>
        </Link>
        <Link to='/app/validar'>
          <button className="btn btn-outline-success option-buttons">Seguir un paseo</button>
        </Link>
        </div>
      </section>
    );
  }
}

export default ModeSelect;